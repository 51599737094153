/*
 * General custom scripts
 **/
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import { debounce } from './utils/debounce';

window.onload = (function () {
    var body = document.body;
    body.classList.add('load');
})();

// Header, Menu, Mobile
// ------------------------------------------------------------------------------

var hamburger = document.getElementsByClassName('ph__hamburger');
for (var i = 0; i < hamburger.length; i++) {
    hamburger[i].addEventListener('click', function (event) {
        document.getElementsByTagName('header')[0].classList.toggle('open');
        this.classList.toggle('is-active');
    });
}

var handle = document.getElementsByClassName('ph__menu-top-handle');
for (var i = 0; i < handle.length; i++) {
    handle[i].addEventListener('click', function (event) {
        jQuery(this).closest('li').find('ul').slideToggle('slow');
    });
}

// Header, Menu pc
// ------------------------------------------------------------------------------

let menuItems = document.querySelectorAll('.ph__menu-top ul li.depth0');
menuItems.forEach(function (menuItem) {
    menuItem.addEventListener('mouseover', function (event) {
        let width_narrow = document.querySelectorAll('header .size_narrow')[0]
            .clientWidth;
        let width_wide =
            document.querySelectorAll('header .size_wide')[0].clientWidth;
        width_wide = document.querySelector('body').clientWidth;
        let margin = (width_wide - width_narrow) / 2;

        let menu_position =
            document.getElementById('menu-top').offsetLeft + margin;

        if (
            typeof event.currentTarget.querySelectorAll('ul')[0] != 'undefined'
        ) {
            event.currentTarget.querySelectorAll('ul')[0].style.left =
                '-' + menu_position + 'px';
            event.currentTarget.querySelectorAll('ul')[0].style.width =
                width_wide + 'px';

            event.currentTarget.querySelectorAll('ul')[0].style.paddingLeft =
                margin + 'px';
            event.currentTarget.querySelectorAll('ul')[0].style.paddingRight =
                margin + 'px';
        }
    });
});

// Search
// ------------------------------------------------------------------------------

let search = document.querySelector('.ph__search');
let searchInput = document.querySelector('.ph__search input[type="text"]');
search.addEventListener('mouseover', function (event) {
    document.getElementsByTagName('header')[0].classList.add('is-search');
});
search.addEventListener('mouseout', function (event) {
    document.getElementsByTagName('header')[0].classList.remove('is-search');
});

searchInput.addEventListener('focus', function (event) {
    document
        .getElementsByTagName('header')[0]
        .classList.add('is-search-permanent');
});
searchInput.addEventListener('blur', function (event) {
    document
        .getElementsByTagName('header')[0]
        .classList.remove('is-search-permanent');
});

jQuery('.ph__search form').submit(function (e) {
    if (jQuery(this).find('input[name=s]').val().length < 1) {
        e.preventDefault();
    }
});

// Footer
// ------------------------------------------------------------------------------

var handle = document.getElementsByClassName('pf__menu-top-handle');
for (var i = 0; i < handle.length; i++) {
    handle[i].addEventListener('click', function (event) {
        this.closest('li').classList.toggle('open');
    });
}

// Scroll effect
// ------------------------------------------------------------------------------

var posMemory = 0;

function scrollEffect() {
    let pos = window.scrollY;
    if (pos > posMemory) {
        document.getElementsByTagName('header')[0].classList.add('dir-bottom');
        document.getElementsByTagName('header')[0].classList.remove('dir-top');
    } else {
        document.getElementsByTagName('header')[0].classList.add('dir-top');
        document
            .getElementsByTagName('header')[0]
            .classList.remove('dir-bottom');
    }

    if (pos > 1) {
        jQuery('header').addClass('sticked');
    } else {
        document.getElementsByTagName('header')[0].classList.remove('sticked');
    }

    posMemory = pos;
}

setTimeout(function () {
    scrollEffect();
}, 100);

window.onscroll = function () {
    scrollEffect();
};

// Form Gravity
// ------------------------------------------------------------------------------

let selectsGravity = document.querySelectorAll('.gfield_select');
selectsGravity.forEach(function (item) {
    item.addEventListener('click', function (event) {
        this.classList.add('init');
    });
});

//fix bad color after invalid submition

jQuery(document).bind('gform_post_render', function () {
    let selectsGravity = document.querySelectorAll('.gfield_select');
    selectsGravity.forEach(function (item) {
        if (item.value != '') {
            item.classList.add('init');
        }
        item.addEventListener('click', function (event) {
            this.classList.add('init');
        });
    });
});

let selects = document.querySelectorAll('.select-nice select');
selects.forEach(function (item) {
    item.addEventListener('click', function (event) {
        this.classList.add('init');
    });
});

jQuery(document).bind('gform_post_render', function () {
    let selects = document.querySelectorAll('.select-nice select');
    selects.forEach(function (item) {
        if (item.value != '') {
            item.classList.add('init');
        }
        item.addEventListener('click', function (event) {
            this.classList.add('init');
        });
    });
});

// Pagination
// ------------------------------------------------------------------------------

function pagination() {
    let shortmin = 3;

    if (document.querySelectorAll('.pagination .number').length > shortmin) {
        let numbers = document.querySelectorAll('.pagination .number');
        let numberActive = document.querySelector('.pagination .number.active');

        numbers[0].classList.remove('off');
        numbers[numbers.length - 1].classList.remove('off');

        if (
            numberActive.previousSibling.previousSibling.previousSibling
                .previousSibling !== null
        )
            numberActive.previousSibling.previousSibling.previousSibling.previousSibling.classList.remove(
                'off'
            );
        if (numberActive.previousSibling.previousSibling !== null)
            numberActive.previousSibling.previousSibling.classList.remove(
                'off'
            );
        numberActive.classList.remove('off');
        if (numberActive.nextSibling.nextSibling !== null)
            numberActive.nextSibling.nextSibling.classList.remove('off');
        if (
            numberActive.nextSibling.nextSibling.nextSibling.nextSibling !==
            null
        )
            numberActive.nextSibling.nextSibling.nextSibling.nextSibling.classList.remove(
                'off'
            );

        if (numbers[0].nextSibling.nextSibling.classList.contains('off')) {
            let li = document.createElement('li');
            li.classList.add('sep');
            li.innerHTML = '...';
            numbers[0].after(li);
        }
        if (
            numbers[
                numbers.length - 1
            ].previousSibling.previousSibling.classList.contains('off')
        ) {
            let li = document.createElement('li');
            li.classList.add('sep');
            li.innerHTML = '...';
            numbers[numbers.length - 1].before(li);
        }
    } else {
        let numbers = document.querySelectorAll('.pagination .number');
        numbers.forEach(function (item) {
            item.classList.remove('off');
        });
    }
}

pagination();

// Social
// ---------------------------------------------------------------------------------------------------

var share = document.getElementsByClassName('fz-share');
for (var i = 0; i < share.length; i++) {
    share[i].addEventListener('click', function (event) {
        let href = event.target.getAttribute('href');
        window.open(
            href,
            'window',
            'height=450, width=700, top=' +
                (window.innerHeight / 2 - 225) +
                ', left=' +
                (window.innerWidth / 2 - 350) +
                ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
        );
        return false;
    });
}

// Anchor
// ---------------------------------------------------------------------------------------------------

let links = document.querySelectorAll('a');
for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', function (event) {
        let href;
        for (var l = 0; l < event.path.length; l++) {
            if (event.path[l].nodeName == 'A')
                href = event.path[l].getAttribute('href');
        }
        if (href.search('#') == 0) {
            let element = document.getElementById(href.substr(1, 999));
            element.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });

            event.preventDefault();
            return false;
        }
    });
}

// Tabbed Content - Equal Heights for blocks
// ---------------------------------------------------------------------------------------------------
(() => {
    function setTabContentHeight() {
        function getMinHeight(selector) {
            let min_height = 0;

            for (var i = 0; i < selector.length; i++) {
                selector[i].style.minHeight = '';
                let curr_height = selector[i].offsetHeight;
                if (curr_height > min_height) {
                    min_height = curr_height;
                }
            }

            return min_height;
        }

        function setMinHeight(selector, height) {
            if (window.innerWidth < 992) {
                height = '';
            } else {
                height = height + 'px';
            }

            for (var i = 0; i < selector.length; i++) {
                selector[i].style.minHeight = height;
            }
        }

        let tabs_content = document.querySelectorAll(
            '.block-tabbed-content__tabs-wrapper.left-tabs .single-content'
        );

        let tabs_wrapper = document.querySelector(
            '.block-tabbed-content__tabs-wrapper.left-tabs'
        );

        if (!tabs_content || !tabs_wrapper) return;

        let tabs_height = (() => {
            let height = 0;
            const els = document.querySelectorAll(
                '.block-tabbed-content__tabs-wrapper.left-tabs .block-tabbed-content__block-buttons .tab-btn'
            );

            for (const el of els) {
                height += el.offsetHeight || 0;
            }

            return height;
        })();

        let min_height = getMinHeight(tabs_content);

        if (min_height < tabs_height) {
            setMinHeight(tabs_content, tabs_height);
            setMinHeight(tabs_wrapper, min_height);
        } else {
            setMinHeight(tabs_content, min_height);
            setMinHeight(tabs_wrapper, min_height);
        }
    }

    var setTabContentHeightDebounced = debounce(setTabContentHeight, 100);
    window.addEventListener('resize', setTabContentHeightDebounced);
    setTabContentHeight();
})();
